import React, { useEffect, useState, useRef } from "react";
import { Outlet } from "react-router-dom";
import ImageModal from "./ImageModal";
import NewBuildModal from "./NewBuildModal";
import { ModalProvider } from "../hooks/useModal";
import {
  fetchProjectsWithLink,
  verifyPassword,
} from "../../lib/api/ProjectHandler";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubcategoryDetailsModal from "./SubcategoryDetailsModal";

export default function HomeLayout() {
  const [username, setUserName] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const dropdownRef = useRef(null);
  const [role, setRole] = useState("");
  const [link, setLink] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [isNewClick, setIsNewClick] = useState(false);
  const [noProject, setNoProject] = useState(false);
  useEffect(() => {
    setRole(
      localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).role
        : ""
    );

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const isPassword = async () => {
    const project = localStorage.getItem("project");
    const isPasswordEntered = sessionStorage.getItem("password");

    setLink(project);
    const projectData = await fetchProjectsWithLink(project);
    if (projectData.message == "Project does not exist") {
      setNoProject(true);
    } else {
      console.log(">>>>>>>>>>##############<<<<<<<<<<", projectData.password);
      if (projectData.password == "true" && !isPasswordEntered) {
        setShowPopup(true);
      }
    }
  };

  const confirmSave = () => {
    closeModal();

    const customEvent = new CustomEvent("myCustomEvent", {
      detail: {
        callbackFc: confirmNewBuild,
      },
    });
    window.dispatchEvent(customEvent);
  };

  useEffect(() => {
    const name = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).username
      : "";
    setUserName(name);
    isPassword();
  }, []);

  const handleNewBuild = (e) => {
    //yahan
    localStorage.removeItem("products");
    window.location.href = "/home";
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const confirmNewBuild = () => {
    localStorage.removeItem("build");
    localStorage.removeItem("products");
    sessionStorage.removeItem("selectedItems");
    sessionStorage.removeItem("password");
    closeModal();
    handleNewBuild();
  };

  const sharedStates = {
    passwordPopupState: { showPopup, setShowPopup },
  };

  return (
    <>
      {" "}
      {noProject && <ProjectPopUp setNoProject={setNoProject} />}

      {showPopup && <PasswordPopup link={link} setShowPopup={setShowPopup} />}
      <ModalProvider states={sharedStates}>
        {/* <nav className="pushy pushy-left" data-focus="#first-link">
          <div className="pushy-content">
            <ul>
              <li className="pushy-link">
                <a href="#" className="menu-notification relative">
                  <div className="user-profile">
                    AU
                    <span>3</span>
                  </div>

                  <div className="profile-name-mobile">
                    {username}

                    <div className="profile-category">User</div>
                  </div>
                </a>
              </li>
            
              <li className="pushy-submenu pushy-link">
                <a onClick={handleNewBuild} href="#">
                  <div className="menu-img  dashbord-icon"></div>
                  <div>New Build</div>
                </a>
              </li>

              <li className="pushy-link">
                <a href="#">
                  <div className="menu-img active devices-icon"></div>
                  <div className="active-menu-text">How to Use</div>
                </a>
              </li>
              <li className="pushy-link">
                <a href="#">
                  <div className="menu-img users-icon"></div>
                  <div className="btn-round">Connect</div>
                </a>
              </li>
            </ul>
          </div>
        </nav> */}
        {/* <div className="site-overlay"></div> */}

        <div>
          <header>
            <link rel="stylesheet" href={"/bootstrap.css"} />

            <div className="menu-trigger" style={{ display: "none" }}>
              {" "}
              <img src="images/menu-button.svg" />{" "}
            </div>

            <div className="header-logo">
              <img
                onClick={(e) => (window.location.href = "/home")}
                style={{ cursor: "pointer" }}
                src={"/assets/images/bos-logo.png"}
                className="logo"
              />
            </div>

            <div className="header-icons">
              <div className="top-menu header-menu">
                <ul>
                  <li onClick={openModal}>New Build</li>
                  <li onClick={(e) => (window.open("/how-to-use", "_blank"))}>How to Use</li>
                  <li
                    onMouseEnter={() => setDropdownVisible(true)}
                    ref={dropdownRef}
                    style={{ position: "relative", cursor: "pointer" }}>
                    <a style={{ padding: 0 }}>{username}</a>
                    <img src={"/assets/images/user-icon.png"} alt="User Icon" />
                    {dropdownVisible && (
                      <div
                        className="dropdown-menu"
                        style={{
                          position: "fixed",
                          top: "12%",
                          left: "83%",
                          transform: "translate(-50%, -50%)",
                          backgroundColor: "white",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          zIndex: 1,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "8px 0", // Add padding to the dropdown
                        }}>
                        {role === "ADMIN" && (
                          <>
                            <a
                              href="/edit-builds"
                              style={{
                                display: "block",
                                padding: "8px 16px",
                                color: "black",
                              }}>
                              Edit Builds
                            </a>
                            <a
                              href="/projects"
                              style={{
                                display: "block",
                                padding: "8px 16px",
                                color: "black",
                              }}>
                              Projects
                            </a>
                          </>
                        )}
                        {role === "TEAM_MEMBER" && (
                          <a
                            href="/projects"
                            style={{
                              display: "block",
                              padding: "8px 16px",
                              color: "black",
                            }}>
                            Projects
                          </a>
                        )}
                        <a
                          href="/builds"
                          style={{
                            display: "block",
                            padding: "8px 16px",
                            color: "black",
                          }}>
                          View Builds
                        </a>
                        <a
                          href="/logout"
                          style={{
                            display: "block",
                            padding: "8px 16px",
                            color: "red",
                          }}>
                          Logout
                        </a>
                      </div>
                    )}
                  </li>
                  <li>
                    <a
                      href="https://www.bos.com/connect/"
                      target="_blank"
                      className="btn-round">
                      Connect
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <button className="menu-btn">
              <img src="/assets/images/menu.svg" />
            </button>
          </header>

          <div className="main-container">
            <Outlet />
          </div>

          <div className="container-fluid footer text-center">
            <p>Copyright BOS Holdings, All Rights Reserved</p>
          </div>
        </div>
        <ConfirmModal
          show={modalVisible}
          onClose={closeModal}
          onConfirm={confirmNewBuild}
          onSave={confirmSave}
        />
        <ImageModal />
        <NewBuildModal />
        <SubcategoryDetailsModal />
      </ModalProvider>
      <ToastContainer />
    </>
  );
}

const ConfirmModal = ({ show, onClose, onConfirm, onSave }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
      }}>
      <div
        style={{
          backgroundColor: "white",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          textAlign: "center",
          width: "400px",
          padding: "20px",
        }}>
        <p style={{ fontSize: "16px", marginBottom: "20px" }}>
          Are you sure you want to create a new build? All changes will be lost.
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={onConfirm}
            style={{
              backgroundColor: "#c8102e",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              margin: "10px",
              width: "100px",
            }}>
            OK
          </button>
          <button
            onClick={onSave}
            style={{
              backgroundColor: "#a8102e",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              margin: "10px",
              width: "100px",
            }}>
            Save
          </button>
          <button
            onClick={onClose}
            style={{
              backgroundColor: "#242531",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              margin: "10px",
              width: "100px",
            }}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const PasswordPopup = ({ link, setShowPopup }) => {
  const [password, setPassword] = useState("");

  const handleSubmit = async () => {
    // Validate password
    try {
      const pass = await verifyPassword(link, password);
      if (pass?.message && pass?.message == "success") {
        setShowPopup(false);
        sessionStorage.setItem("password", true);
      } else {
        toast.error("Incorrect Password");
      }
    } catch {
      toast.error("Incorrect Password");
    }
  };

  return (
    <div style={popupOverlayStyle}>
      <div style={popupContentStyle}>
        <h3>Enter Password to Proceed</h3>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={inputStyle}
        />
        <button onClick={handleSubmit} style={buttonStyle}>
          Submit
        </button>
      </div>
    </div>
  );
};

const popupOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const popupContentStyle = {
  backgroundColor: "#fff",
  padding: "18px",
  borderRadius: "8px",
  textAlign: "center",
  width: "300px",
};

const inputStyle = {
  width: "100%",
  padding: "10px",
  margin: "10px 0",
  borderRadius: "4px",
  border: "1px solid #ccc",
};

const buttonStyle = {
  padding: "10px 20px",
  borderRadius: "4px",
  border: "none",
  backgroundColor: "#007bff",
  color: "#fff",
  cursor: "pointer",
};



const ProjectPopUp = ({ setNoProject }) => {

  const handleSubmit = async () => {
   setNoProject(false);
   localStorage.removeItem("project")
   window.location.href = "/how-to-use";
  };

  return (
    <div style={popupOverlayStyle}>
      <div style={popupContentStyle}>
        <h3 style={{marginBottom : "25px" }}>Somthing went wrong!<br/> The Project might have been deleted!</h3>
        <button onClick={handleSubmit} style={buttonStyle}>
          Proceed
        </button>
      </div>
    </div>
  );
};
import React, { useEffect, useState } from "react";
import { useModal } from "../hooks/useModal";

export default function ProductBox({
  productGroup,
  onIncrementItems,
  products,
  category,
  margin,
}) {
  const { openModal } = useModal();

  const openImageModal = (title, id, imageData, link) => {
    openModal({ title, id, src: imageData, link }, "image");
  };

  useEffect(() => {
    cleanUpLocalStorage();
  }, [productGroup, products]);

  const cleanUpLocalStorage = () => {
    console.log('prrrd', products)
    if(products && products.length > 0){
      const storedProducts = localStorage.getItem('build') || "";
      // const updatedProducts = { ...storedProducts };

      // Object.keys(updatedProducts).forEach((key) => {
      //   const product = updatedProducts[key];
      //   const isSelected = products.some(
      //     (selected) => selected.identified === product.identified
      //   );
    
      //   if (!isSelected) {
      //     delete updatedProducts[key];
      //   }
      // });
    
      
      // Update localStorage with cleaned-up products
      // localStorage.setItem('products', JSON.stringify(updatedProducts));  
      localStorage.setItem('build', storedProducts)
  }
  };

  return (
    <>
      {productGroup?.products.good && (
        <ProductItem
          imageModal={openImageModal}
          product={productGroup.products.good}
          tag={"good"}
          onIncrementItems={onIncrementItems}
          name={productGroup.name}
          category={category}
          itemQuantity={products.find(
            (q) => q.identified == `${productGroup.name}good`
          )}
          margin={margin}
          dedicatedHeadCount={productGroup.products.good.dedicatedHeadCount}
        />
      )}
      {productGroup?.products.better && (
        <ProductItem
          imageModal={openImageModal}
          product={productGroup.products.better}
          tag={"better"}
          onIncrementItems={onIncrementItems}
          name={productGroup.name}
          category={category}
          itemQuantity={products.find(
            (q) => q.identified == `${productGroup.name}better`
          )}
          margin={margin}
          dedicatedHeadCount={productGroup.products.better.dedicatedHeadCount}
        />
      )}
      {productGroup?.products.best && (
        <ProductItem
          imageModal={openImageModal}
          product={productGroup.products.best}
          tag={"best"}
          onIncrementItems={onIncrementItems}
          name={productGroup.name}
          category={category}
          itemQuantity={products.find(
            (q) => q.identified == `${productGroup.name}best`
          )}
          margin={margin}
          dedicatedHeadCount={productGroup.products.best.dedicatedHeadCount}
        />
      )}
    </>
  );
}

const ProductItem = ({
  imageModal,
  product,
  tag,
  onIncrementItems,
  name,
  itemQuantity,
  category,
  margin,
  dedicatedHeadCount,
}) => {
  const [quantity, setQuantity] = useState(itemQuantity?.quantity || 0);
  const [totalListPrice, setTotalListPrice] = useState(0);
  const [totalSalePrice, setTotalSalePrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [totalSquareFootage, setTotalSquareFootage] = useState(0);
  const [productImage, setProductImage] = useState("");
  const [totalDedicatedHeadCount, setTotalDedicatedHeadCount] = useState(
    dedicatedHeadCount || 0
  );


  useEffect(() => {
   console.log('inside pb', itemQuantity?.quantity);
    if(itemQuantity?.quantity){
      setQuantity(itemQuantity?.quantity);
    }
    console.log('inside pb pro', product);
    console.log('margin', margin);
    const unitPriceString = product.unitCost;
    if (unitPriceString) {
      const up = unitPriceString.replace("$", "");
      const unitPrice = parseInt(up);
      const disPrice = unitPrice / (1 - margin);
      const commas = parseFloat(disPrice.toFixed(2));
      setDiscountedPrice(commas.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }));
    }
  }, [itemQuantity?.quantity]);

  useEffect(() => {
    if (product && product.selectedFile) {
      try {
        if (typeof product.selectedFile == "object") {
          setProductImage(
            `data:image/png;base64,${product.selectedFile.imageData}`
          );
        } else {
          setProductImage(
            `data:image/png;base64,${
              JSON.parse(product.selectedFile).imageData
            }`
          );
        }
      } catch (e) {}
    }
  }, [product]);

  const incrementQuantity = () => setQuantity((prev) => Number(prev) + 1);
  const decrementQuantity = () =>
    setQuantity((prev) => (prev > 0 ? prev - 1 : 0));

  const handleQuantityChange = (e) => {
    let value = e.target.value;

    if (value === "" || isNaN(value)) {
      value = 0;
    } else {
      value = value.replace(/^0+/, "");
      if (value === "") {
        value = "0";
      }
    }
    const numericValue = parseInt(value, 10);
    if (!isNaN(numericValue) && numericValue >= 0) {
      // setQuantity(String(numericValue));
      setQuantity(String(numericValue));
    }
  };

  useEffect(() => {
    
    setTotalSalePrice(processCurrencyString(discountedPrice, quantity));
    setTotalSquareFootage(product.squareFootage * quantity);
    setTotalListPrice(processCurrencyString(product.listPrice, quantity));
    setTotalDedicatedHeadCount(dedicatedHeadCount);

  }, [quantity]);

  useEffect(() => {
    setTotalSalePrice(processCurrencyString(discountedPrice, quantity));
  }, [discountedPrice])

  useEffect(() => {
    
    const sprice = processCurrencyString(discountedPrice, quantity);
    console.log('quant 22', product)
    onIncrementItems(
      name,
      tag,
      totalListPrice || processCurrencyString(product.listPrice, quantity),
      quantity,
      sprice,
      product.squareFootage * quantity,
      category,
      product.name,
      productImage,
      totalDedicatedHeadCount
    );
  }, [totalListPrice, quantity]);


  useEffect(() => {
    if (itemQuantity?.quantity) {
       setQuantity(itemQuantity.quantity);
    }
 }, [itemQuantity]);

  function processCurrencyString(currencyString, multiplier) {
    console.log("multiplier", currencyString, multiplier);
    if (currencyString) {
     
      let cString = currencyString.replace("$", "");
      let numericString = cString?.replace(/[^\d.]/g, "");
      let parts = numericString.split(".");
      let cleanedString = parts[0] + "." + parts.slice(1).join("");

      let number = parseFloat(cleanedString);
      let multipliedValue = number * multiplier;

      let resultString = multipliedValue.toFixed(2);
      return parseFloat(resultString);
    }
    else{
      return 0;
    }
  }

  return (
    <div className="threeBox-item">
      <div className="img-box">
        <div
          className="image-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}>
          {productImage ? (
            <img
              src={productImage}
              className="box-item-img"
              style={{
                maxWidth: "343px",
                maxHeight: "270px",
                minWidth: "310px",
                minHeight: "270px",
                objectFit: 'cover',
                padding: '5%',
                // width: "auto",
                // height: "auto",
              }}
              alt=""
            />
          ) : (
            <img
              src="/assets/images/img-box-1.png"
              className="box-item-img"
              style={{
                maxWidth: "343px",
                maxHeight: "270px",
                minWidth: "310px",
                minHeight: "270px",
                width: "auto",
                height: "auto",
              }}
              alt=""
            />
          )}
        </div>

        <img
          src={process.env.PUBLIC_URL + `/assets/images/${tag}-tag.png`}
          className="tag-img"
          alt=""
        />
        
        <img
          src={process.env.PUBLIC_URL + "/assets/images/zoom-icon.png"}
          onClick={() =>
            imageModal(
              product.name,
              product.desc,
              productImage || "/assets/images/img-box-1.png",
              product.link
            )
          }
          className="zoom-icon"
          alt=""
        />
      </div>
      <div className="item-light">
        <h2 className="f-bold pt-3 pb-3 pl-3">{product.name}</h2>
        <div className="item-list">
          <div className="price-tag">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/dollar-icon.png"}
                alt=""
              />
            </div>
            <div>List Price</div>
          </div>
          <div className="amount-item f-bold pr-2 text-cross">
            $
            {parseFloat(product.listPrice.replace("$", "")).toLocaleString(
              "en-US",
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}
          </div>
        </div>
        <div className="item-list">
          <div className="price-tag">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/per-icon.png"}
                alt=""
              />
            </div>
            <div>Discounted Sell Price</div>
          </div>
          <div className="amount-item f-bold pr-2">
          {console.log('disprice', discountedPrice)}
            {!!discountedPrice && discountedPrice.startsWith("$")
              ? discountedPrice
              : !!discountedPrice && !isNaN(discountedPrice.replaceAll(",", ""))
              ? "$" + discountedPrice
              : "$0.00"}
          </div>
        </div>
        <div className="item-list">
          <div className="price-tag">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/scale-icon.png"}
                alt=""
              />
            </div>
            <div>Square Feet (per seat)</div>
          </div>
          <div className="amount-item f-bold pr-2">{product.squareFootage}</div>
        </div>
        <div className="item-list">
          <div className="price-tag">
            <div>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/images/people-icon-f.png"
                }
                alt=""
                width="19px"
                height="19px"
              />
            </div>
            <div>Dedicated Headcount</div>
          </div>
          <div className="amount-item f-bold pr-2">
            {(totalDedicatedHeadCount || 0)}
          </div>
        </div>
      </div>
      <div className="item-dark">
        <div className="item-list py-3 px-3">
          <div className="f-14 f-bold">Quantity</div>
          <input
            type="number"
            value={quantity}
            onChange={handleQuantityChange}
            onWheel={() => document.activeElement.blur()}
            min={0}
            placeholder="0"
          />
          <button onClick={decrementQuantity}>
            <img
              src={process.env.PUBLIC_URL + "/assets/images/icon-minus.png"}
              alt="Decrement"
            />
          </button>
          <button onClick={incrementQuantity}>
            <img
              src={process.env.PUBLIC_URL + "/assets/images/icon-plus.png"}
              alt="Increment"
            />
          </button>
        </div>
        <div className="item-list">
          <div className="price-tag">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/dollar-icon.png"}
                alt=""
              />
            </div>
            <div>Total List Price</div>
          </div>
          <div className="amount-item f-bold pr-2 theme-primary-color text-cross">
            $
            {typeof totalListPrice === "number"
              ? totalListPrice.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : totalListPrice}
          </div>
        </div>
        <div className="item-list">
          <div className="price-tag">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/per-icon.png"}
                alt=""
              />
            </div>
            <div>Total Sell Price</div>
          </div>
          <div className="amount-item f-bold pr-2 theme-primary-color">
            $
            {typeof totalSalePrice === "number" && !isNaN(totalSalePrice)
              ? totalSalePrice.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : typeof totalSalePrice === "string" &&
                !isNaN(Number(totalSalePrice.replaceAll(",", "")))
              ? Number(totalSalePrice).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : "0.00"}
          </div>
        </div>
        <div className="item-list">
          <div className="price-tag">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/scale-icon.png"}
                alt=""
              />
            </div>
            <div>Total Square Feet</div>
          </div>
          <div className="amount-item f-bold pr-2 theme-primary-color">
            {totalSquareFootage.toLocaleString("en-US")}
          </div>
        </div>
        <div className="item-list">
          <div className="price-tag">
            <div>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/images/people-icon-f.png"
                }
                alt=""
                width="19px"
                height="19px"
              />
            </div>
            <div>Total Dedicated Headcount</div>
          </div>
          <div className="amount-item f-bold pr-2">
          {(totalDedicatedHeadCount * quantity || 0).toLocaleString("en-US")}
          </div>
        </div>
      </div>
    </div>
  );
};

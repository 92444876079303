import React, { useEffect, useMemo, useState } from "react";
import { fetchBuildByName } from "../lib/api/BuildHandler";
import { getCategoryNameByProductGroup } from "../lib/api/CategoriesHandler";
import classNames from "classnames";

export default function Report() {
  const [build, setBuild] = useState(null);
  const [buildName, setBuildName] = useState("");
  // const [build, set] = useState(null);
  const [categories, setCategories] = useState(null);
  const [dateTime, setDateTime] = useState(null);

  useEffect(() => {
    setDateTime(formatDateTime());
  }, []);

  function printme(d) {
    console.log("dd", d);
  }

  useEffect(() => {
    if (build) {
      let price = 0,
        lPrice = 0,
        sqFeet = 0,
        seats = 0;

      Object.values(build).forEach((productsArray) => {
        productsArray.forEach((product) => {
          price += product.price;

          lPrice += product.sellPrice;
          sqFeet = addFloats(sqFeet, product.squarFootage);
          console.log("report product: ", product.dedicatedHeadCount);
          seats += product.quantity * product.dedicatedHeadCount;
        });
      });

      setTotalListPrice(price);
      setTotalSellPrice(lPrice);
      setTotalSquareFootage(sqFeet);
      setTotalSeats(seats);
      setPricePerSquateFootage(lPrice/(sqFeet *1.18) || 0);

      // setTotalListPrice(
      //   products.reduce((acc, item) => addFloats(acc, item.price), 0)
      // );
      // setTotalSellPrice(
      //   products.reduce((acc, item) => addFloats(acc, item.sellPrice), 0)
      // );
      // setTotalSquareFootage(
      //   products.reduce((acc, item) => addFloats(acc, item.squarFootage), 0)
      // );
      // setTotalSeats(products.reduce((acc, item) => acc + item.quantity, 0));
    }

    console.log("bbuild", JSON.stringify(build));
  }, [build]);

  const [totalSellPrice, setTotalSellPrice] = useState(0);
  const [totalListPrice, setTotalListPrice] = useState(0);
  const [totalSeats, setTotalSeats] = useState(0);
  const [totalSquareFootage, setTotalSquareFootage] = useState(0);
  const [pricePerSquateFootage, setPricePerSquateFootage] = useState(0);

  const [productImage, setProductImage] = useState("");

  // useEffect(() => {
  //   if(build && product.selectedFile){
  //     setProductImage(`data:image/svg+xml;base64,${JSON.parse(product.selectedFile).imageData}`);
  //   }
  // }, [build])

  const fetchBuild = async () => {
    
    if (localStorage.getItem("products")) {
      const res = await fetchBuildByName(localStorage.getItem("build"));
      console.log("plao", localStorage.getItem("build"));
      if (res) {
        
        const products = JSON.parse(localStorage.getItem("products"));
        const name = localStorage.getItem("build");
        setBuildName(name);
        console.log("bef", JSON.stringify(res));
        const newData = await convertToGroupedObject(res);

       
        setBuild(newData);
      } else {
        console.log("no res");
      }
      // localStorage.removeItem("build")
    }
  };

  function addFloats(num1, num2, precision = 10) {
    // Convert to fixed precision to avoid floating point issues
    let result = (parseFloat(num1) + parseFloat(num2)).toFixed(2);
    // Convert back to float to remove trailing zeros
    return parseFloat(result);
  }

  function convertToGroupedObject(data) {
    // Initialize an empty object to store the grouped items
    console.log("groupedd", data);
    const groupedObject = {};
    const uniqueGroups = [];

    // Iterate through each item in the data array
    data.forEach((item) => {
      // Extract id and name directly
      const { id, name, ...rest } = item;

      // Iterate through the keys (0, 1, 2, etc.) to group by category
      Object.keys(rest).forEach((key) => {
        if (rest[key].quantity == 0) {
          return;
        }
        const category = rest[key].category;

        // Create the category key in groupedObject if it doesn't exist
        if (!groupedObject[category]) {
          groupedObject[category] = [];
        }

        if (uniqueGroups.includes(rest[key].name + category)) {
          rest[key].name = "";
        } else {
          uniqueGroups.push(rest[key].name + category);
        }

        // Push the item under the corresponding category
        groupedObject[category].push(rest[key]);
      });

      // Add id and name to groupedObject as-is
      // groupedObject.id = id;
      // groupedObject.name = name;
      delete groupedObject.id;
      delete groupedObject.name;
    });

    return groupedObject;
  }

  useEffect(() => {
    setDateTime(formatDateTime());
  }, []);

  useMemo(() => {
    fetchBuild();
  }, []);

  const checkPageHeight = () => {
    const elements = document.querySelectorAll('.threeBox-list-wraps');
    let maxCount = 8;
    let current = 0;
   
elements.forEach((el, index) => {
  const previousEl = el.previousSibling;
  current++;
  
  if (previousEl && previousEl.firstChild && previousEl.firstChild.tagName == 'H2') {
    
    maxCount = maxCount > 5 ? maxCount-1 : maxCount;
    
  }
  
  if (current % maxCount === 0) {
    el.style.height = '500px';
    maxCount = 8;
    current = 0;
  }

});
};


  useEffect(() => {
    if (build) {
      // checkPageHeight(); // Ensure page height check is called after render
    }
  }, [build]);

  const formatDateTime = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} T ${hours}:${minutes}:${seconds}`;
  };

  return (
    build && (
      <div className="row m-0">
        <div className="col-lg-12">
          <section id="content">
            <div className="section-wrapper">
              <div className="report-header mb-5 container">
                <div>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/bos-logo.png"}
                    alt=""
                  />
                </div>
                <div className="text-right">
                  <h1>{buildName}</h1>
                  <p>Report Created On : {dateTime}</p>
                </div>
              </div>
              {Object.keys(build).map((key, index) => (
                <>
                  
                  <div className="report-title-wrap container">
                    <h2>{key}</h2>
                  </div>

                  <div className="chkbox-wrap container mt-4 pt-1">
                    {build[key].map((item, index) => (
                      <>
                        <div>
                          {item.name && (
                            <h2
                              className={classNames(
                                index > 0 ? "mt-4" : "",
                                "f-20 f-color-red f-bold"
                              )}>
                              {item.name}
                            </h2>
                          )}
                        </div>
                        <div className="threeBox-list-wraps">
                          <div className="threeBox-item report-list-wrap">
                            <div
                              className="img-box report-img-box"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%",
                              }}>
                              {console.log("imd", item)}
                              <img
                                src={
                                  item.productImage ||
                                  item.selectedFile?.imgData
                                }
                                style={{
                                  maxWidth: "343px",
                                  maxHeight: "210px",
                                  minWidth: "210px",
                                  minHeight: "210px",
                                  width: "auto",
                                  objectFit: "cover",
                                  // height: 'auto'
                                }}
                                className="box-item-img"
                                alt=""
                              />
                              {printme(item)}
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/${item.tag}-tag.png`}
                                className="tag-img"
                                alt=""
                              />
                            </div>
                            <div className="item-light">
                              <h2 className="f-14 f-bold pt-3 pb-3 pl-3">
                                {item.productName}
                              </h2>
                              <div className="item-list">
                                <div className="price-tag">
                                  <div>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/dollar-icon.png"
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div>List Price</div>
                                </div>
                                <div className="amount-item f-bold pr-2 text-cross h2c-black">
                                  $
                                  {(
                                    item.price / item.quantity || 0
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </div>
                              </div>
                              <div className="item-list">
                                <div className="price-tag">
                                  <div>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/per-icon.png"
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div>Discounted Sell Price</div>
                                </div>
                                <div className="amount-item f-bold pr-2">
                                  $
                                  {(
                                    item.sellPrice / item.quantity || 0
                                  ).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </div>
                              </div>
                              <div className="item-list">
                                <div className="price-tag">
                                  <div>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/scale-icon.png"
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div>Square Feet (per seat)</div>
                                </div>
                                <div className="amount-item f-bold pr-2">
                                  {(
                                    item.squarFootage / item.quantity || 0
                                  ).toLocaleString("en-US")}
                                </div>
                              </div>
                              <div className="item-list">
                                <div className="price-tag">
                                  <div>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/people-icon-f.png"
                                      }
                                      style={{
                                        width: "19px",
                                        height: "19px",
                                      }}
                                      alt=""
                                    />
                                  </div>
                                  <div>Dedicated Headcount</div>
                                </div>
                                <div className="amount-item f-bold pr-2">
                                  {(item.dedicatedHeadCount || 0).toLocaleString("en-US")}
                                </div>
                              </div>
                            </div>
                            <div className="item-dark">
                              <div className="item-list py-3 px-3">
                                <div className="f-14 f-bold">Quantity</div>
                                <div className="f-14 f-bold">
                                  {item.quantity.toLocaleString("en-US")}
                                </div>
                              </div>
                              <div className="item-list">
                                <div className="price-tag">
                                  <div>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/dollar-icon.png"
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div>Total List Price</div>
                                </div>
                                <div className="amount-item f-bold pr-2 f-color-red text-cross">
                                  $
                                  {item.price.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </div>
                              </div>
                              <div className="item-list">
                                <div className="price-tag">
                                  <div>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/per-icon.png"
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div>Total Sell Price</div>
                                </div>
                                <div className="amount-item f-bold pr-2 f-color-red">
                                  $
                                  {item.sellPrice &&
                                    item.sellPrice.toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                </div>
                              </div>
                              <div className="item-list">
                                <div className="price-tag">
                                  <div>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/scale-icon.png"
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div>Total Square Feet</div>
                                </div>
                                <div className="amount-item f-bold pr-2 f-color-red">
                                  {item.squarFootage.toLocaleString("en-US")}
                                </div>
                              </div>
                              <div className="item-list">
                                <div className="price-tag">
                                  <div>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/people-icon-f.png"
                                      }
                                      style={{
                                        width: "19px",
                                        height: "19px",
                                      }}
                                      alt=""
                                    />
                                  </div>
                                  <div>Total Dedicated Headcount</div>
                                </div>
                                <div className="amount-item f-bold pr-2 f-color-red">
                                  {(parseInt(item.dedicatedHeadCount) *
                                    item.quantity || 0).toLocaleString("en-US")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      // {/* </div> */}
                    ))}
                  </div>
                </>
              ))}

              <div className="container report-tag-wrap">
                <div className="container">
                  <div className="report-tag-item">
                    <div>
                      <img
                        src="/assets/images/people-icon-f.png"
                        alt="People Icon"
                      />
                    </div>
                    <div>
                      <h3>Dedicated Seats</h3>
                      <h2>{totalSeats.toLocaleString("en-US")}</h2>
                    </div>
                  </div>
                  <div className="report-tag-item">
                    <div>
                      <img
                        src="/assets/images/doller-icon-f.png"
                        alt="Dollar Icon"
                      />
                    </div>
                    <div>
                      <h3>Total List Price</h3>
                      <h2 className="text-cross">
                        $
                        {totalListPrice.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </h2>
                    </div>
                  </div>
                  <div className="report-tag-item">
                    <div>
                      <img
                        src="/assets/images/per-icon-f.png"
                        alt="Percentage Icon"
                      />
                    </div>
                    <div>
                      <h3>Total Sell Price</h3>
                      <h2>
                        $
                        {totalSellPrice.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </h2>
                    </div>
                  </div>

                  <div className="report-tag-item">
                    <div>
                      <img
                        src="/assets/images/scale-icon-f.png"
                        alt="Scale Icon"
                      />
                    </div>
                    <div>
                      <h3>Total Square Feet</h3>
                      <h2 className="theme-primary-color">
                        {totalSquareFootage.toLocaleString("en-US")}
                      </h2>
                    </div>
                  </div>



                  <div class="report-tag-item">
                  <div>
                    <img src="/assets/images/doller-outlne-icon-f.png" alt="" />
                  </div>
                  <div>
                    <h3>Price Per Square Foot</h3>
                    <h2 class="theme-primary-color">${pricePerSquateFootage.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}</h2>
                  </div>
                </div>


                  
                </div>
              </div>
            </div>{" "}
            {/* Section ends here */}
          </section>
        </div>
      </div>
    )
  );
}
